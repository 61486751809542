import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import {Box, Button, Grid, Modal} from '@material-ui/core'
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import {calculatePeriodLabelFromNumberOfDays} from "../../commonUtils/misc";

const useStyles = makeStyles(theme => ({
    menu: {
        width: 200,
    },
    closeButtonCalendar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
    },
    field: {
        flex: 1,
        margin: 8,
        minWidth: 200,
    },
    paddingCalendar: {
        padding: '5px',
    },
    calendar: {
        width: '80%',
        backgroundColor: 'white',
        padding: '32px',
        borderRadius: '10px',
        boxShadow: '0 0 20px rgba(0,0,0,0.3)',
    },
    textField6: {
        width: 'calc(50% - 16px)',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    datePickerTextField: {
        border: "none !important",
        background: "transparent !important",
        cursor: "pointer",
    },
    durationItem: {
        marginTop: '5px',
    }
}))

const RESORT_THEMES = [
    {value: 1, label: 'Mare'},
    {value: 2, label: 'Montagna'},
    {value: 3, label: 'Terme'},
    {value: 4, label: 'Altro'},
]

export const CATALOGS = [
    {value: 1, label: 'Exchange experience'},
    {value: 2, label: 'Study & Travel experience'},
    {value: 3, label: 'Premium experience'}
]

export default function PackageSelector(props) {
    let [filteredDatePeriods, setFilteredDatePeriods] = useState(null);
    let [sourceDatePeriods, setSourceDatePeriods] = useState(null);
    let [showCalendar, setShowCalendar] = useState(false);
    let [durations, setDurations] = useState(null);
    const [firstCalendarDate, setFirstCalendarDate] = useState(new Date());
    const [selectedDuration, setSelectedDuration] = useState(null);
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    const [secondCalendarDate, setSecondCalendarDate] = useState(date);
    const [selectedTurnDate, setSelectedTurnDate] = useState(null);
    const [calendarModalOpen, setCalendarModalOpen] = useState(false);

    const classes = useStyles()
    const {
        title = 'Scegli il soggiorno',
        groupsTitle = 'Programma',
        turnsTitle = 'Seleziona il periodo',
        noTurnsText = 'Non ci sono turni disponibili per questo pacchetto',
        departuresTitle = 'Città di partenza',
        noDeparturesText = 'Nessuna città disponibile',
        structuresTitle = 'Seleziona la tipologia di camera',
        programTypologiesTitle = 'Tipologia di programma',
        noProgramTypologiesText = 'Nessuna tipologia di programma disponibile',
        enableStructures = false,
        enableProgramTypologies = false,
        enableTurnPrice = false,
        enableResortTheme = false,
        enableCatalogs = false,

        noPackagesAvailable,
        noDepartureServicesAvailable,
        noProgramTypologiesAvailable,

        clusterCode,

        onCatalogChange,
        onNationChange,
        onResortThemeChange,
        onWebGroupChange,
        onPackageChange,
        onDepartureServiceChange,
        onStructureChange,
        onProgramTypologyChange,
        state,
        applicationType,
    } = props
    const {
        catalog = '',
        catalogs = [],
        nations = [],
        nation = '',
        resortTheme = '',
        webGroups = [],
        webGroup = '',
        packages = [],
        package: packageId = '',
        departureServices = [],
        departureService = '',
        structures = [],
        structure,
        programTypologies = [],
        programTypology,
    } = state
    console.info("state", state);
    const errors = state.errors || {}
    const {
        nation: nationError = null,
        webGroup: webGroupError = null,
        package: packageError = null,
        catalog: catalogError = null,
        departureService: departureServiceError = null,
        structure: structureError,
        programTypology: programTypologyError,
    } = errors


    // console.info("props", props);
    const isAdaForm = applicationType === 'ada'

    const showStructuresField = isAdaForm
        ? true
        : enableStructures && structures.length > 0

    let countryMd = 4;
    if (isAdaForm) countryMd = 3

    let packageMd = 8
    if (isAdaForm) packageMd = 6
    if (enableCatalogs) packageMd = 4


    useEffect(() => {
        if (packages.length > 0) {
            console.info('packages', packages);
            //show calendar is true if in all packages there are "packageType" === '24A661'
            showCalendar = packages.some(({packageType}) => packageType === '24A661');
            setShowCalendar(showCalendar);
            if (showCalendar) {
                console.info('packages', packages);
                filteredDatePeriods = sourceDatePeriods = packages.map(({value, packageFrom, packageTo}) => {
                    const dateFrom = moment(packageFrom, "DD/MM/YYYY");
                    const dateTo = moment(packageTo, "DD/MM/YYYY");
                    const days = dateTo.diff(dateFrom, 'days');
                    const label = calculatePeriodLabelFromNumberOfDays(days);
                    return {
                        id: value,
                        dateFrom: dateFrom,
                        textFieldPrint: "Dal " + dateFrom.format('DD/MM/YYYY') + " al " + dateTo.format('DD/MM/YYYY') + " (" + label + ")",
                        dateTo: dateTo,
                        duration: {
                            days,
                            label: label
                        }
                    };
                });
                durations = filteredDatePeriods.map(({duration}) => duration);
                //rimuovi i duplicati di durations (days uguali) e ordina in maniera decrescente
                durations = durations.filter((duration, index, self) =>
                        index === self.findIndex((t) => (
                            t.days === duration.days
                        ))
                ).sort((a, b) => b.days - a.days);
                setFilteredDatePeriods(filteredDatePeriods);
                setSourceDatePeriods(filteredDatePeriods);
                setDurations(durations);
                console.info('datePeriods', filteredDatePeriods);
                console.info('durations', durations);
            }
        } else {
            //reset data
            setShowCalendar(false);
            setFilteredDatePeriods(null);
            setSourceDatePeriods(null);
            setDurations(null);
            setFirstCalendarDate(new Date());
            const newDate = new Date();
            date.setMonth(date.getMonth() + 1);
            setSecondCalendarDate(new Date());
            setSelectedTurnDate(null);
        }
    }, [packages]);

    useEffect(() => {
        if (selectedDuration) {
            console.info('selectedDuration num days', selectedDuration);
            //filtro tutti i periodi che hanno la stessa durata
            const periods = sourceDatePeriods.filter(({duration}) => duration.days === selectedDuration.days);
            console.info('periods', periods);
            setFilteredDatePeriods(periods);
        }
    }, [selectedDuration]);


    useEffect(() => {
        if (selectedTurnDate) {
            console.info('selectedTurnDate', selectedTurnDate);
            //prendo il periodo corretto
            const correctPeriod = filteredDatePeriods.find(d => d.dateFrom.isSame(selectedTurnDate, 'day'));
            console.info('correctPeriod', correctPeriod);
            //stampo l'id
            console.info('selectedPeriodId', correctPeriod.id);
            //salvo il periodo
            onPackageChange(correctPeriod.id);
        }
    }, [selectedTurnDate]);


    const renderCalendar = ({key, onChangeView, activeDate, value}) =>
        <Grid item xs={6} className={classes.paddingCalendar}>
            <Calendar
                tileDisabled={({date, view}) => {
                    if (view === 'month' && filteredDatePeriods.some(d => d.dateFrom.isSame(date, 'day'))) {
                        return false;
                    }
                    return true;
                }}
                tileClassName={({date, view}) => {
                    let classes = [];
                    if (view === 'month' && filteredDatePeriods.some(d => d.dateFrom.isSame(date, 'day'))) {
                        classes.push('dayEnabled');
                    }
                    if (selectedTurnDate) {
                        //imposta dayEnabled in tutte le date che sono uguali a selectedTurnDate e anche le date successive fino a dateTo dove dateFrom è uguale a selectedTurnDate
                        const correctPeriod = filteredDatePeriods.find(d => d.dateFrom.isSame(selectedTurnDate, 'day'));
                        if (view === 'month' && date >= correctPeriod?.dateFrom && date <= correctPeriod?.dateTo) {
                            classes.push('daySelected');
                        }
                    }

                    return classes.length > 0 ? classes.join(" ") : null;
                }}
                key={key}
                onChange={(newDate) => setSelectedTurnDate(newDate)}
                onActiveStartDateChange={onChangeView}
                defaultActiveStartDate={activeDate}
                activeStartDate={activeDate}
                value={value}
            />
        </Grid>

    return (
        <>
            <div className="booking-box">
                <Typography variant="h6">{title}</Typography>

                <Grid container style={{display: 'flex', flexWrap: 'wrap'}}>

                    {enableCatalogs && (
                        <Grid item md={4} xs={12} className={classes.field}>
                            <TextField
                                select
                                fullWidth
                                value={catalog}
                                error={catalogError}
                                onChange={event => onCatalogChange(event.target.value)}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                helperText="Seleziona tipo di catalogo *"
                                margin="normal">
                                {catalogs.map(({value, label}) => (
                                    <MenuItem key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}

                    <Grid item md={countryMd} xs={12} className={classes.field}>
                        <TextField
                            id="standard-select-country"
                            select
                            fullWidth
                            value={nation || ''}
                            error={nationError}
                            onChange={event => onNationChange(event.target.value)}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText={isAdaForm ? "Seleziona la tipologia di soggiorno *" : "Seleziona paese di destinazione *"}
                            margin="normal">
                            {nations.map(({value, label}) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {enableResortTheme && (
                        <Grid item md={3} xs={12} className={classes.field}>
                            <TextField
                                select
                                fullWidth
                                value={resortTheme}
                                error={nationError}
                                onChange={event => onResortThemeChange(event.target.value)}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                helperText="Seleziona il tipo di vacanza *"
                                margin="normal">
                                {RESORT_THEMES.map(({value, label}) => (
                                    <MenuItem key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}

                    <Grid md={packageMd} xs={12} className={classes.field}>
                        <TextField
                            id="standard-select-package"
                            select
                            fullWidth
                            value={webGroup || ''}
                            error={webGroupError}
                            onChange={event => onWebGroupChange(event.target.value)}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            helperText={`${groupsTitle} *`}
                            margin="normal">
                            {webGroups.map(({value, label}) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>

                <Grid container>

                    {/*La variabile è stata introdotta per mostrare il calendario per ADA*/}
                    {showCalendar ?
                        <Grid item md={8} xs={12} className={classes.field}>
                            <TextField
                                id="select-date-turns"
                                inputProps={
                                    {
                                        readOnly: true,
                                        className: classes.datePickerTextField,
                                    }
                                }
                                fullWidth
                                helperText={`${turnsTitle} *`}
                                margin="normal"
                                value={selectedTurnDate ? filteredDatePeriods.find(d => d.dateFrom.isSame(selectedTurnDate, 'day'))?.textFieldPrint : ''}
                                onClick={(_) => setCalendarModalOpen(true)}
                            >

                            </TextField>

                            {/*Modale che mostra il calendario di selezione per ADA*/}
                            <Modal open={calendarModalOpen} onClose={(_) => setCalendarModalOpen(false)}>
                                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                                    <Grid container item className={classes.calendar}>
                                        <Grid container direction={'column'}>
                                            <Grid container item>
                                                {(durations?.length > 0) &&
                                                    <Grid container item direction={'column'} xs={3}>
                                                        <Typography variant={'button'}>Durata Soggiorno</Typography>
                                                        {durations?.map((item, index) =>
                                                            <Button
                                                                className={classes.durationItem}
                                                                variant={selectedDuration?.label === item.label ? 'outlinedSecondary' : 'outlined'}
                                                                size={'small'}
                                                                key={index}
                                                                onClick={() => setSelectedDuration(item)}
                                                            >
                                                                {item.label}
                                                            </Button>)}
                                                    </Grid>}
                                                {(durations?.length > 0) && <Grid item xs={1}/>}
                                                <Grid container direction={'column'} item
                                                      xs={(durations?.length > 0) ? 8 : 12}>
                                                    <Typography variant={'button'}>
                                                        Seleziona Data di partenza
                                                    </Typography>
                                                    <Grid container item>
                                                        {renderCalendar({
                                                            key: 'first-calendar',
                                                            onChangeView: (args) => {
                                                                const {activeStartDate} = args;
                                                                setFirstCalendarDate(activeStartDate);

                                                                const newDate = new Date(activeStartDate);
                                                                newDate.setMonth(newDate.getMonth() + 1);
                                                                setSecondCalendarDate(newDate);
                                                            },
                                                            activeDate: firstCalendarDate,
                                                            value: selectedTurnDate
                                                        })}
                                                        {renderCalendar({
                                                            key: 'second-calendar',
                                                            onChangeView: (args) => {
                                                                const {activeStartDate} = args;
                                                                setSecondCalendarDate(activeStartDate);

                                                                const newDate = new Date(activeStartDate);
                                                                newDate.setMonth(newDate.getMonth() - 1);
                                                                setFirstCalendarDate(newDate);
                                                            },
                                                            activeDate: secondCalendarDate,
                                                            value: selectedTurnDate
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.closeButtonCalendar}>
                                                <Button
                                                    variant={'contained'}
                                                    onClick={() => setCalendarModalOpen(false)}
                                                >
                                                    Conferma pacchetto
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Modal>
                        </Grid> :
                        <Grid item md={8} xs={12} className={classes.field}>
                            {noPackagesAvailable ? (
                                <TextField
                                    id="standard-select-turns"
                                    disabled
                                    fullWidth
                                    helperText={`${turnsTitle} *`}
                                    margin="normal"
                                    value={noTurnsText}>
                                    {/* {noTurnsText} */}
                                </TextField>
                            ) : (
                                <TextField
                                    id="standard-select-turns"
                                    select
                                    fullWidth
                                    value={packageId || ''}
                                    error={packageError}
                                    onChange={event => onPackageChange(event.target.value)}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    helperText={`${turnsTitle} *`}
                                    margin="normal">
                                    {packages
                                        // .slice(0, 3)
                                        .map(({value, label, price, allPricesForMemberCluster, soldout}) => {
                                            let finalPrice = price
                                            if (
                                                clusterCode &&
                                                allPricesForMemberCluster &&
                                                allPricesForMemberCluster[clusterCode]
                                            ) {
                                                const cluster = allPricesForMemberCluster[clusterCode]
                                                finalPrice = cluster.price != null ? cluster.price : price
                                            }
                                            return (
                                                <MenuItem disabled={soldout} key={value} value={value}>
                                                    {label}
                                                    {/* @Agata qui si nasconde il prezzo */}
                                                    {!soldout && enableTurnPrice && finalPrice > 0 && (
                                                        <span>&nbsp;- €{(finalPrice || 0) / 100}</span>
                                                    )}
                                                    {soldout && (
                                                        <span>&nbsp;- SOLDOUT</span>
                                                    )}
                                                </MenuItem>
                                            )
                                        })}
                                </TextField>
                            )}
                        </Grid>
                    }

                    <Grid item md={4} xs={12} className={classes.field}>
                        {noDepartureServicesAvailable ? (
                            <TextField
                                id="standard-select-checkin"
                                disabled
                                fullWidth
                                helperText={`${departuresTitle} *`}
                                margin="normal"
                                value={noDeparturesText}></TextField>
                        ) : (
                            <TextField
                                id="standard-select-checkin"
                                select
                                fullWidth
                                helperText={`${departuresTitle} *`}
                                value={departureService || ''}
                                error={departureServiceError}
                                onChange={event => onDepartureServiceChange(event.target.value)}
                                disabled={noPackagesAvailable}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                margin="normal">
                                {departureServices.map(({value, label, soldout}) => (
                                    <MenuItem disabled={soldout} key={value} value={value}>
                                        {soldout ? `${label} - SOLDOUT` : label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    </Grid>
                </Grid>

                {showStructuresField && (
                    <TextField
                        id="standard-select-checkin"
                        select
                        fullwidth
                        className={classes.textField8 + ' select col-xs-12'}
                        helperText={`${structuresTitle} *`}
                        value={structure || ''}
                        error={structureError}
                        onChange={event => onStructureChange(event.target.value)}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        margin="normal">
                        {structures.map(({value, label}) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                )}

                {!enableProgramTypologies ? null : noProgramTypologiesAvailable ? (
                    <TextField
                        id="standard-select-checkin"
                        fullWidth
                        disabled
                        className={classes.textField6 + ' select col-xs-12'}
                        helperText={`${programTypologiesTitle} *`}
                        margin="normal"
                        value={noProgramTypologiesText}>
                        {/* Non ci sono tipologie di programma disponibili per questo turno/data */}
                    </TextField>
                ) : (
                    <TextField
                        id="standard-select-checkin"
                        select
                        fullWidth
                        className={classes.textField6 + ' select col-xs-12'}
                        helperText={`${programTypologiesTitle} *`}
                        value={programTypology || ''}
                        error={programTypologyError}
                        onChange={event => onProgramTypologyChange(event.target.value)}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        margin="normal">
                        {programTypologies.map(({value, label}) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            </div>
        </>
    )
}

PackageSelector.propTypes = {
    title: PropTypes.string,
    groupsTitle: PropTypes.string,
    turnsTitle: PropTypes.string,
    noTurnsText: PropTypes.string,
    departuresTitle: PropTypes.string,
    noDeparturesText: PropTypes.string,
    structuresTitle: PropTypes.string,
    programTypologiesTitle: PropTypes.string,
    noProgramTypologiesText: PropTypes.string,
    enableStructures: PropTypes.bool,
    enableProgramTypologies: PropTypes.bool,
    enableTurnPrice: PropTypes.bool,

    noPackagesAvailable: PropTypes.bool.isRequired,
    noDepartureServicesAvailable: PropTypes.bool.isRequired,
    noProgramTypologiesAvailable: PropTypes.bool,

    state: PropTypes.shape({
        nations: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            })
        ),
        nation: PropTypes.string,
        webGroups: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            })
        ),
        webGroup: PropTypes.string,
        packages: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.number.isRequired,
                label: PropTypes.string.isRequired,
                price: PropTypes.number.isRequired,
                allPricesForMemberCluster: PropTypes.object,
            })
        ),
        // loadingTurns: PropTypes.bool.isRequired,
        package: PropTypes.number,
        departureServices: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                price: PropTypes.number.isRequired,
                soldout: PropTypes.bool,
            })
        ),
        // loadingDepartureServices: PropTypes.bool.isRequired,
        departureService: PropTypes.string,
        structures: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                price: PropTypes.number.isRequired,
            })
        ),
        structure: PropTypes.string,
        programTypologies: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            })
        ),
        programTypology: PropTypes.string,
        errors: PropTypes.shape({
            nation: PropTypes.string,
            webGroup: PropTypes.string,
            package: PropTypes.string,
            departureService: PropTypes.string,
            structure: PropTypes.string,
            programTypology: PropTypes.string,
        }),
    }).isRequired,
    clusterCode: PropTypes.string,

    onNationChange: PropTypes.func.isRequired,
    onWebGroupChange: PropTypes.func.isRequired,
    onPackageChange: PropTypes.func.isRequired,
    onDepartureServiceChange: PropTypes.func.isRequired,
    onStructureChange: PropTypes.func,
    onProgramTypologyChange: PropTypes.func,
}
