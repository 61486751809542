import * as actions from './PackageSelectorActions'

export const initialState = Object.freeze({
  errors: null,
  loadingNations: false,
  catalogs: [],
  nations: [],
  nation: null,
  loadingWebGroups: false,
  webGroups: [],
  webGroup: null,
  loadingPackages: false,
  packages: [],
  package: null,
  loadingDepartureServices: false,
  departureServices: [],
  departureService: null,
  loadingStructures: false,
  structures: [],
  structure: null,
  resortTheme: null,
  loadingProgramTypologies: false,
  programTypologies: [],
  programTypology: null,
})

export function reducer(state, action) {
  switch (action.type) {
    case actions.INIT: {
      const {
        catalogs = [],
        catalog = null,
        nations = [],
        nation = null,
        webGroups = [],
        webGroup = null,
        loadingPackages = false,
        resortTheme = '',
      } = action
      return {
        ...initialState,
        catalogs,
        catalog,
        nations,
        nation,
        webGroups,
        webGroup,
        loadingPackages,
        resortTheme,
      }
    }
    case actions.INIT_ALL: {
      const {
        catalogs = [],
        catalog = null,
        nations = [],
        nation = null,
        webGroups = [],
        webGroup = null,
        packages = [],
        package: choosenPackage = null,
        departureServices = [],
        departureService = null,
        structures = [],
        structure = null,
        programTypologies = [],
        programTypology = null,
      } = action
      return {
        ...initialState,
        catalogs,
        catalog,
        nations,
        nation,
        webGroups,
        webGroup,
        packages,
        package: choosenPackage,
        departureServices,
        departureService,
        structures,
        structure,
        programTypologies,
        programTypology,
      }
    }
    case actions.RESET: {
      return {...initialState}
    }
    case actions.RESET_ALL_BUT_NATIONS: {
      const {nations} = state
      return {...initialState, nations}
    }
    case actions.SET_ERRORS: {
      const {errors = {}} = action
      return {...state, errors}
    }
    // Catalogs
    case actions.LOAD_CATALOGS: {
      return {...initialState, loadingCatalogs: true}
    }
    case actions.SET_CATALOGS: {
      return {
        ...state,
        loadingCatalogs: false,
        catalogs: action.catalogs || [],
      }
    }
    case actions.CHOOSE_CATALOG: {
      //
      const {catalogs} = state
      const {catalog, loadNations = false} = action
      return {
        ...initialState,
        catalogs,
        catalog,
        loadingNations: loadNations,
      }
    }

    // Nations
    case actions.LOAD_NATIONS: {
      const {catalogs, catalog} = state
      return {...initialState,
        catalogs,
        catalog,
        loadingNations: true
      }
    }
    case actions.SET_NATIONS: {
      return {
        ...state,
        loadingNations: false,
        nations: action.nations || [],
      }
    }
    case actions.CHOOSE_NATION: {
      //
      const {catalogs, catalog, nations} = state
      const {nation, loadWebGroups = false} = action
      return {
        ...initialState,
        catalogs,
        catalog,
        nations,
        nation,
        loadingWebGroups: loadWebGroups,
      }
    }
    // WebGroups
    case actions.LOAD_WEB_GROUPS: {
      const {catalogs, catalog, nations, nation} = state
      return {
        ...initialState,
        catalogs,
        catalog,
        nations,
        nation,
        loadingWebGroups: true,
      }
    }
    case actions.SET_WEB_GROUPS:
      return {
        ...state,
        loadingWebGroups: false,
        webGroups: action.webGroups || [],
      }
    case actions.CHOOSE_WEB_GROUP: {
      const {catalogs, catalog, nations, nation, webGroups, resortTheme} = state
      const {webGroup, loadPackages = false} = action
      return {
        ...initialState,
        catalogs,
        catalog,
        nations,
        nation,
        webGroups,
        webGroup,
        resortTheme,
        loadingPackages: loadPackages,
      }
    }
    // Packages
    case actions.LOAD_PACKAGES: {
      const {catalogs, catalog, nations, nation, webGroups, webGroup} = state
      return {
        ...initialState,
        catalogs,
        catalog,
        nations,
        nation,
        webGroups,
        webGroup,
        loadingPackages: true,
      }
    }
    case actions.SET_PACKAGES: {
      return {
        ...state,
        loadingPackages: false,
        packages: action.packages || [],
      }
    }
    case actions.CHOOSE_PACKAGE: {
      const {catalogs, catalog, nations, nation, webGroups, webGroup, packages} = state
      const {
        package: choosenPackage,
        loadDepartureServices = false,
        loadStructures = false,
        loadProgramTypologies = false,
      } = action

      return {
        ...state,
        catalogs,
        catalog,
        nations,
        nation,
        webGroups,
        webGroup,
        packages,
        package: choosenPackage,
        loadingDepartureServices: loadDepartureServices,
        loadingStructures: loadStructures,
        loadingProgramTypologies: loadProgramTypologies,
      }
    }
    // DepartureServices
    case actions.LOAD_DEPARTURE_SERVICES: {
      return {
        ...state,
        loadingDepartureServices: true,
        departureServices: [],
        departureService: null,
      }
    }
    case actions.SET_DEPARTURE_SERVICES: {
      return {
        ...state,
        loadingDepartureServices: false,
        departureServices: action.departureServices || [],
      }
    }
    case actions.CHOOSE_DEPARTURE_SERVICE: {
      return {
        ...state,
        errors: null,
        departureService: action.departureService,
      }
    }
    // Structures
    case actions.LOAD_STRUCTURES: {
      return {
        ...state,
        loadingStructures: true,
        structures: [],
        structure: null,
      }
    }
    case actions.SET_STRUCTURES: {
      return {
        ...state,
        loadingStructures: false,
        structures: action.structures || [],
      }
    }

    case actions.CHOOSE_RESORT_THEME: {
      const {resortTheme, loadWebGroups = false} = action
      return {
        ...state,
        errors: null,
        resortTheme,
        loadingWebGroups: loadWebGroups,
      }
    }

    case actions.CHOOSE_STRUCTURE: {
      const {structure, loadWebGroups = false} = action
      return {
        ...state,
        errors: null,
        structure,
        loadingWebGroups: loadWebGroups,
      }
    }
    // ProgramTypologies
    case actions.LOAD_PROGRAM_TYPOLOGIES: {
      return {
        ...state,
        loadingProgramTypologies: true,
        programTypologies: [],
        programTypology: null,
      }
    }
    case actions.SET_PROGRAM_TYPOLOGIES: {
      return {
        ...state,
        loadingProgramTypologies: false,
        programTypologies: action.programTypologies || [],
      }
    }
    case actions.CHOOSE_PROGRAM_TYPOLOGY: {
      return {
        ...state,
        errors: null,
        programTypology: action.programTypology,
      }
    }

    default:
      throw new Error('Unexpected action')
  }
}
