export function convertCountDown(timeInSeconds) {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return {hours, minutes, seconds};
}

export function calculatePeriodLabelFromNumberOfDays(numberOfDays) {
    let label;
    if (numberOfDays % 7 === 0) {
        const weeks = numberOfDays / 7;
        label = `${weeks} settiman${weeks > 1 ? 'e' : 'a'}`;
    } else {
        label = `${numberOfDays} giorn${numberOfDays > 1 ? 'i' : 'o'}`;
    }
    return label;
}
